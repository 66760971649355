import React, { useCallback, useContext, useEffect, useLayoutEffect, useState, useRef } from 'react';
import { navigate } from 'gatsby';
import { css } from '@emotion/react';
import { ThemeContext } from './theme-provider';
import Logo from './logo';
import Pill from './pill';
import { Back, Cart, Close, Menu, Search } from './icons';
import SearchBar from './search-bar';
import Box from './box';
import CaptionCard from './caption-card';
import { randomGoodColor, randomColor } from './colors';
import PillDropdown from './pill-dropdown';

export default function Header({ className, location, savedCount, backHeader }) {
  const theme = useContext(ThemeContext);

  const [backButton, setBackButton] = useState(true);
  const [menuOpen, setMenuOpen] = useState(false);
  const [searchOpen, setSearchOpen] = useState(false);
  const [displayCaptionCard, setDisplayCaptionCard] = useState(true);
  const [hoverColor, setHoverColor] = useState(randomGoodColor());
  const [query, setQuery] = useState('');
  const [resultCount, setResultCount] = useState(null);
  const [hide, setHide] = useState(true);
  const [hideBackTop, setHideBackTop] = useState(true);
  const [cartHoverColor, setCartHoverColor] = useState(null);
  const [logoInvert, setLogoInvert] = useState(false);
  const [logoHide, setLogoHide] = useState(false);
  const [scrollY, setScrollY] = useState(0);

  const inputRef = useRef();

  useLayoutEffect(() => {
    const collection = localStorage.getItem('collection');
    const collectionName = localStorage.getItem('collection-name');

    if (collection) {
      theme.setCollection(new Set([...collection.split(',')]));
    }

    if (collectionName) {
      theme.setCollectionName(collectionName);
    }
  }, []);

  useLayoutEffect(() => {
    setHide(theme.headerHide);
  }, [theme.headerHide]);

  useLayoutEffect(() => {
    if (theme.backURL) {
      setBackButton(true);
    } else {
      setBackButton(false);
    }
  }, [theme.backURL]);

  const handleScroll = useCallback((e) => {
    const y = window.scrollY;

    if (window.location.pathname === '/' || location.pathname.includes('/tenet/')) {
    } else {
      if (y > 0 && y > scrollY) {
        setHide(true);
      } else {
        setHide(false);
      }

      if (y > (window.innerHeight * 1.5) && y < document.documentElement.scrollHeight - window.innerHeight) {
        setHideBackTop(false);
      } else {
        setHideBackTop(true);
      }
    }
    setScrollY(y);
  }, [scrollY]);

  useLayoutEffect(() => {
    setScrollY(window.scrollY);
    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll, scrollY]);

  useEffect(() => {
    inputRef.current.focus();
  }, [searchOpen]);

  useEffect(() => {
    localStorage.setItem('collection', [...theme.collection]);
  }, [theme.collection]);

  useEffect(() => {
    localStorage.setItem('collection-name', theme.collectionName);
  }, [theme.collectionName]);

  useEffect(() => {
    setLogoHide(theme.logoHide);
  }, [theme.logoHide]);

  useEffect(() => {
    setLogoInvert(theme.logoInvert);
  }, [theme.logoInvert]);

  return (
    <>
      {!backHeader && (
        <>
          <header
            className={className}
            css={css`
              display: flex;
              padding: var(--gutter) var(--gutter) 3.6rem;
              opacity: ${hide ? 0 : 1};
              pointer-events: ${hide ? 'none' : 'auto'};
              transition: opacity 0.6s var(--curve);
              will-change: opacity;
              pointer-events: none;

              @media (max-width: 800px) {
                padding: 3.5rem var(--gutter);
              }

              @media (max-width: 550px) {
                padding: 2.4rem var(--gutterHalf);
              }
            `}
          >
            <div
              css={css`
                flex-basis: 50%;
                opacity: ${logoHide ? 0 : 1};
                transition: opacity 0.6s var(--curve);
                will-change: opacity;

                svg {
                  width: 17.4rem;
                  height: 5.4rem;
                }

                @media (max-width: 550px) {
                  svg {
                    width: 12.7rem;
                    height: 3.9rem;
                  }
                }
              `}
            >
              <Logo
                url="/"
                css={css`
                  display: inline-block;
                  pointer-events: ${logoHide ? 'none' : 'auto'};

                  svg {
                    pointer-events: none;
                  }

                  &:hover {
                    svg path {
                      fill: ${hoverColor};
                    }
                  }
                `}
                invert={logoInvert}
                updateHoverColor={() => setHoverColor(randomGoodColor())}
              />
            </div>
            <nav
              css={css`
                flex-basis: 50%;

                @media (max-width: 800px) {
                  display: none;
                }
              `}
            >
              <ul
                css={css`
                  display: flex;
                  justify-content: flex-end;
                  align-items: flex-start;
                  list-style: none;
                  padding: 0;
                  margin: 0;

                  li {
                    margin-right: var(--gutterHalf);
                    pointer-events: auto;

                    &:last-child {
                      margin-right: 0;
                    }
                  }
                `}
              >
                <li
                  css={css`
                    opacity: ${!backButton ? 0 : 1};
                    pointer-events: ${!backButton ? 'none' : 'auto'};
                    transition: opacity 0.6s var(--curve);
                    will-change: opacity;
                    padding-right: 2.6rem;
                    border-right: 3px solid var(--black);
                    margin-right: 2.4rem;

                    @media (max-width: 1024px) {
                      display: none;
                    }
                  `}
                >
                  <Back
                    onClick={() => navigate(theme.backURL) }
                  />
                </li>
                <li>
                  <Pill
                    size={2}
                    text="Work"
                    url="/work"
                    hover={true}
                    background="var(--white)"
                    onClick={() => setSearchOpen(false)}
                  />
                </li>
                <li>
                  <PillDropdown 
                    size={2}
                    hover={true}
                    background="var(--white)"
                    onClick={() => setSearchOpen(false)}
                    items={[
                      {
                        text: 'Us',
                      },
                      {
                        text: 'Studio',
                        url: '/studio'
                      },
                      {
                        text: 'Ventures',
                        url: '/ventures'
                      },
                      {
                        text: 'People',
                        url: '/people'
                      },
                      {
                        text: 'Careers',
                        url: '/careers'
                      },
                    ]}
                  />
                </li>
                <li>
                  <Pill
                    size={2}
                    text="Tenets"
                    url="/tenets"
                    hover={true}
                    background="var(--white)"
                    onClick={() => setSearchOpen(false)}
                  />
                </li>
                <li>
                  <Pill
                    size={2}
                    text="News"
                    url="/news"
                    hover={true}
                    background="var(--white)"
                    onClick={() => setSearchOpen(false)}
                  />
                </li>
                <li>
                  <Search
                    onClick={() => {
                      setSearchOpen(true);
                    }}
                    css={css`
                      display: ${searchOpen ? 'none' : 'block'};
                    `}
                  />
                  <Close
                    active={true}
                    onClick={() => setSearchOpen(false)}
                    css={css`
                      display: ${searchOpen ? 'block' : 'none'};
                    `}
                  />
                </li>
                <li>
                  <Cart 
                    url="/collection" 
                    count={theme.collection.size} 
                    active={theme.collection.size}
                    hoverBackground={cartHoverColor}
                    onMouseEnter={() => {
                      setCartHoverColor(randomGoodColor());
                    }}
                    onClick={() => setSearchOpen(false)} />
                </li>
              </ul>
            </nav>
            <div
              css={css`
                display: none;
                margin-left: auto;
                pointer-events: auto;

                @media (max-width: 800px) {
                  display: block;
                }
              `}
            >
              <Menu onClick={() => setMenuOpen(true)} />
            </div>
            <div
              css={css`
                display: none;
                position: fixed;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                padding: 3.5rem var(--gutter);
                background-color: var(--black);
                z-index: 10;
                overflow-y: scroll;
                pointer-events: auto;

                @media (max-width: 800px) {
                  display: ${menuOpen ? 'block' : 'none'};
                }

                @media (max-width: 550px) {
                  padding: 2.2rem var(--gutterHalf);
                }
              `}
            >
              <div
                css={css`
                  display: flex;
                  position: relative;
                  z-index: 20;
                `}
              >
                <div
                  css={css`
                    display: flex;
                    align-items: center;

                    ${searchOpen &&
                    `
                      div {
                        pointer-events: none;

                        svg {
                          circle {
                            fill: var(--white);
                            stroke: var(--black);
                          }
  
                          path {
                            fill: var(--black);
                          }
                        }
                      }
                    `}
                  `}
                >
                  <Search invert={true} onClick={() => setSearchOpen(!searchOpen)} />
                  <span
                    className="type--underline-inverse"
                    css={css`
                      display: ${searchOpen ? 'inline-block' : 'none'};
                      padding-left: 1rem;
                      cursor: pointer;
                    `}
                    onClick={() => setSearchOpen(false)}
                  >
                    Close search
                  </span>
                </div>
                <div
                  css={css`
                    display: flex;
                    margin-left: auto;
                  `}
                >
                  <Cart
                    url="/collection" 
                    count={theme.collection.size}
                    invert={searchOpen ? false : true}
                    active={theme.collection.size}
                    onClick={() => {
                      setMenuOpen(false);
                    }}
                    css={css`
                      margin-right: 2rem;
                    `}
                  />
                  <Close
                    invert={searchOpen ? false : true}
                    onClick={() => {
                      setMenuOpen(false);
                      setSearchOpen(false);
                    }}
                  />
                </div>
              </div>
              <nav
                css={css`
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  min-height: calc(100% - var(--gutter));
                  padding-top: 5.5rem;
                  padding-bottom: var(--margin9);
                `}
              >
                <ul
                  css={css`
                    list-style: none;
                    text-align: center;
                    padding: 0;
                    margin: 0;

                    > li {
                      margin-bottom: 2.2rem;

                      &:last-child {
                        margin-bottom: 0;
                      }

                      div {
                        font-size: 3rem;
                        line-height: 1.1;
                        padding: 1.2rem 3.2rem 0.9rem !important;
                      }
                    }
                  `}
                >
                  <li>
                    <Pill
                      size={3}
                      text="Home"
                      url="/"
                      hover={true}
                      invert={true}
                      onClick={() => setMenuOpen(false)}
                    />
                  </li>
                  <li>
                    <Pill
                      size={3}
                      text="Work"
                      url="/work"
                      hover={true}
                      invert={true}
                      onClick={() => setMenuOpen(false)}
                    />
                  </li>
                  <li>
                    <Pill
                      size={3}
                      text="Studio"
                      url="/studio"
                      hover={true}
                      invert={true}
                      onClick={() => setMenuOpen(false)}
                    />
                  </li>
                  <li>
                    <Pill
                      size={3}
                      text="Ventures"
                      url="/ventures"
                      hover={true}
                      invert={true}
                      onClick={() => setMenuOpen(false)}
                    />
                  </li>
                  <li>
                    <Pill
                      size={3}
                      text="People"
                      url="/people"
                      hover={true}
                      invert={true}
                      onClick={() => setMenuOpen(false)}
                    />
                  </li>
                  <li>
                    <Pill
                      size={3}
                      text="Careers"
                      url="/careers"
                      hover={true}
                      invert={true}
                      onClick={() => setMenuOpen(false)}
                    />
                  </li>
                  <li>
                    <Pill
                      size={3}
                      text="Tenets"
                      url="/tenets"
                      hover={true}
                      invert={true}
                      onClick={() => setMenuOpen(false)}
                    />
                  </li>
                  <li>
                    <Pill
                      size={3}
                      text="News"
                      url="/news"
                      hover={true}
                      invert={true}
                      onClick={() => setMenuOpen(false)}
                    />
                  </li>
                </ul>
              </nav>
              <div
                css={css`
                  position: absolute;
                  top: 0;
                  right: 0;
                  bottom: 0;
                  left: 0;
                  display: ${searchOpen ? 'block' : 'none'};
                  background-color: var(--white);
                  padding-top: var(--marginPageTop);
                  overflow-y: scroll;
                  overscroll-behavior: contain;
                  z-index: 15;
                `}
              >
                <Box x={3}>
                  <SearchBar
                    placeholder="Type to search..."
                    setSearchOpen={setSearchOpen}
                    setMenuOpen={setMenuOpen}
                    onClick={() => setDisplayCaptionCard(false)}
                  />
                </Box>
                <CaptionCard
                  text="If you’re interested in exploring our work we recommend using our intelligent filtering; but if you have something more specific in mind, please go ahead and search above..."
                  linkText="View Work"
                  url="/work"
                  urlOnClick={() => {
                    setSearchOpen(false);
                    setMenuOpen(false);
                  }}
                  closeButton={true}
                  closeButtonOnClick={() => setDisplayCaptionCard(false)}
                  css={css`
                    display: ${displayCaptionCard ? 'block' : 'none'};
                    position: fixed !important;
                    bottom: var(--gutter) !important;
                    left: var(--gutter) !important;
                    margin: 0 !important;

                    @media (max-width: 550px) {
                      bottom: var(--gutterHalf) !important;
                      left: var(--gutterHalf) !important;
                    }
                  `}
                />
              </div>
            </div>
          </header>
          <div
            css={css`
              position: fixed;
              top: 0;
              right: 0;
              bottom: 0;
              left: 0;
              display: ${searchOpen ? 'block' : 'none'};
              background-color: var(--white);
              padding-top: var(--marginPageTop);
              overflow-y: scroll;
              overscroll-behavior: contain;
              z-index: 15;

              @media (max-width: 800px) {
                display: none;
              }
            `}
          >
            <Box x={10}>
              <SearchBar 
                setSearchOpen={setSearchOpen} 
                setMenuOpen={setMenuOpen} 
                onClick={() => setDisplayCaptionCard(false)}
                inputRef={inputRef} />
            </Box>
            <CaptionCard
              text="If you’re interested in exploring our work we recommend using our intelligent filtering; but if you have something more specific in mind, please go ahead and search above..."
              linkText="Use Work Filter"
              url="/work"
              urlOnClick={() => {
                setSearchOpen(false);
                setMenuOpen(false);
              }}
              closeButton={true}
              closeButtonOnClick={() => setDisplayCaptionCard(false)}
              css={css`
                display: ${displayCaptionCard ? 'block' : 'none'};
                position: fixed !important;
                bottom: var(--gutter) !important;
                left: var(--gutter) !important;
                margin: 0 !important;

                @media (max-width: 550px) {
                  bottom: var(--gutterHalf) !important;
                  left: var(--gutterHalf) !important;
                }
              `}
            />
          </div>
        </>
      )}
      {backHeader && (
        <header
          className={`${className} type--paragraph`}
          css={css`
            padding: 1.6rem var(--gutterHalf) 1.2rem;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            opacity: ${hide ? 0 : 1};
            pointer-events: ${hide ? 'none' : 'auto'};
            transition: opacity 0.6s var(--curve);
            will-change: opacity;

            @media (max-width: 550px) {
              font-size: 1.5rem !important;
              line-height: 1.2 !important;
              letter-spacing: 0 !important;
            }
          `}
        >
          <Back
            css={css`
              margin-top: -0.2rem;
              margin-right: 1.7rem;

              @media (max-width: 550px) {
                margin-right: 0.7rem;
              }
            `}
            url="/"
            hover={true}
            transparent={true}
          />
          Home
        </header>
      )}
      <div
        css={css`
          position: fixed;
          width: 100%;
          text-align: center;
          bottom: var(--gutter);
          opacity: ${(hideBackTop) ? 0 : 1};
          pointer-events: ${(hideBackTop) ? 'none' : 'auto'};
          transition: opacity 0.6s var(--curve);
          will-change: opacity;
          z-index: 11;
        `}
      >
        <Pill
          css={css`
            pointer-events: ${(hideBackTop) ? 'none' : 'auto'};
          `}
          onClick={() => {
            window.scrollTo({
              top: 0,
              left: 0,
              behavior: 'smooth',
            });
          }}
          size={2}
          text="Back to Top"
          hover={true}
          background="var(--white)"
          dropShadow={true}
        />
      </div>
    </>
  );
}
